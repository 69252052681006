import { ActionButton, Flex, Form, Heading, ProgressCircle, Text, TextField, View, ViewProps } from '@adobe/react-spectrum';
import { Temporal } from '@js-temporal/polyfill';
import { SpectrumFormProps } from '@react-types/form';
import { ColorVersion } from '@react-types/shared';
import { SpectrumTextFieldProps, TextFieldRef } from '@react-types/textfield';
import CheckmarkCircleOutline from '@spectrum-icons/workflow/CheckmarkCircleOutline';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useRef, useState } from 'react';

const NewsletterSubscription = (props: ViewProps<ColorVersion>) => {
  const emailFieldRef = useRef<TextFieldRef | null>(null);

  const [emailFieldIsInvalid, setEmailFieldIsInvalid] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const getEmailNewsletterSubscriptionText = useCallback(() => {
    const now = Temporal.Now.zonedDateTimeISO('UTC');

    const nextTuesdayAtFourPM = now.add({ days: (9 - now.dayOfWeek) % 7 || 7 }).with({ hour: 16, microsecond: 0, millisecond: 0, minute: 0, second: 0 }).toInstant();

    return `A list of illustration jobs will be sent to your inbox every ${nextTuesdayAtFourPM.toLocaleString('en-US', { weekday: 'long' })} at ${nextTuesdayAtFourPM.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit' })}.`;
  }, []);

  const handleEmailFieldChange: SpectrumTextFieldProps['onChange'] = () => {
    if (emailFieldIsInvalid) {
      setEmailFieldIsInvalid(false);
    }
  };

  const handleSubmit: SpectrumFormProps['onSubmit'] = async event => {
    event.preventDefault();

    const emailField = emailFieldRef.current!;

    if (emailField.getInputElement()!.value === '' || !emailField.getInputElement()!.validity.valid) {
      setEmailFieldIsInvalid(true);
      emailField.focus();
      return;
    }

    setIsSubscribing(true);

    const url: RequestInfo = '/api/subscribe';

    const options: RequestInit = {
      body: JSON.stringify({ email: emailField.getInputElement()!.value }),
      method: 'POST'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`${response.status} (${response.statusText})`);
      }

      setIsSubscribed(true);
    } catch (error_) {
      const error = error_ as Error;
      setIsSubscribing(false);
      console.error(error);
    }
  };

  return (
    <View
      backgroundColor="gray-50"
      borderColor="light"
      borderRadius="regular"
      borderWidth="thin"
      marginBottom="size-100"
      padding="size-200"
      {...props}
    >
      <AnimatePresence
        exitBeforeEnter
        initial={false}
      >
        {isSubscribed ? (
          <motion.div
            key="1"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <Flex
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <CheckmarkCircleOutline
                marginBottom="size-160"
                marginTop="size-50"
                size="XL"
              />
              <Heading
                UNSAFE_style={{
                  fontSize: 'var(--spectrum-alias-heading2-text-size)',
                  fontWeight: 'var(--spectrum-alias-heading-text-font-weight-quiet)',
                  lineHeight: 'var(--spectrum-alias-heading-text-line-height)'
                }}
                level={3}
                marginBottom="size-50"
                marginTop="size-0"
              >
                {'Subscribed!'}
              </Heading>
              <Text
                UNSAFE_style={{
                  fontSize: 'var(--spectrum-alias-font-size-default)',
                  fontStyle: 'var(--spectrum-global-font-style-italic)',
                  fontWeight: 'var(--spectrum-alias-body-text-font-weight)',
                  lineHeight: 'var(--spectrum-alias-body-text-line-height)',
                  textAlign: 'center'
                }}
                marginBottom="size-50"
              >
                {getEmailNewsletterSubscriptionText()}
              </Text>
            </Flex>
          </motion.div>
        ) : (
          <motion.div
            key="2"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <View marginBottom="size-100">
              {'Enter your email address to receive illustration jobs every week.'}
              <br />
              {'Unsubscribe anytime.'}
            </View>
            <Form
              aria-label=""
              isRequired
              minWidth="auto"
              onSubmit={handleSubmit}
            >
              <TextField
                ref={emailFieldRef}
                aria-label="Email"
                inputMode="email"
                onChange={handleEmailFieldChange}
                type="email"
                validationState={emailFieldIsInvalid ? 'invalid' : undefined}
                width="100%"
              />
              <ActionButton
                isDisabled={isSubscribing}
                type="submit"
                width="100%"
              >
                {isSubscribing ? (
                  <ProgressCircle
                    aria-label="Loading..."
                    isIndeterminate
                    size="S"
                    variant="overBackground"
                  />
                ) : 'Subscribe'}
              </ActionButton>
            </Form>
          </motion.div>
        )}
      </AnimatePresence>
    </View>
  );
};

export default NewsletterSubscription;
